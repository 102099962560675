import React from "react"
import PropTypes from "prop-types"

import HorizontalTwoColumns from "../../components/Bulma/HorizontalTwoColumns"
import ImageMeta from "../../components/ImageMeta"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"
import TheaterVideo from "@components/Theater/TheaterVideo"
import MarkdownViewer from "@components/MarkdownViewer"

function ReferringProvider(props) {
  if (!props.data.hasThisSection) {
    return <div></div>
  }

  let post = props.data
  let language = "en"

  return (
    <>
      {language === "es" ? (
        <HorizontalTwoColumns
          className="continuum es-continuum"
          leftColWidth={3}
          rightColWidth={3}
          middleColWidth={2}
          leftColumn={
            <div className="column is-7">
              <h2>Atención Continua de Nuestros Odontólogos Remitentes</h2>
              <p className="large">
                La conexión del consultorio con su red de remisiones es
                esencial. Nuestro equipo promueve una comunicación abierta entre
                nuestros consultorios remitentes de modo que los pacientes
                puedan disfrutar de una experiencia óptima, lo que en última
                instancia mejora la coordinación de su atención.
              </p>
            </div>
          }
          rightColumn={
            <div className="column">
              <ImageMeta
                cloudName="nuvolum"
                publicId={post.imageId}
                responsive
              />
            </div>
          }
        />
      ) : (
        <HorizontalTwoColumns
          className="continuum is-vcentered color-back"
          leftColWidth={3}
          rightColWidth={3}
          middleColWidth={1}
          leftColumn={
            <div className="column is-8" style={{ margin: "auto 0" }}>
              <h2 className="h3-style" style={{ marginTop: 0 }}>
                {post.heading}
              </h2>
              <MarkdownViewer markdown={post.text} />
              <div className="referring-provider-theater-button-desktop">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                  playing={true}
                  onlyButton={true}
                  sizzleButton={true}
                  buttonClass="sizzle-button-under contained"
                  compId={"sizzleTheaterButton"}
                  language={language}
                />
              </div>
            </div>
          }
          rightColumn={
            <div className="column">
              <ImageMeta
                cloudName="nuvolum"
                publicId={post.imageId}
                responsive
              />
              <p
                style={{
                  color: "#6d6e6f",
                  fontSize: "14px",
                  lineHeight: "14px",
                  fontWeight: "bold",
                  marginTop: "19px",
                  marginBottom: "0",
                }}
              >
                {post.imageCaption}
              </p>
              <div className="referring-provider-theater-button-mobile">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=L1FIWtc92K4`}
                  playing={true}
                  onlyButton={true}
                  sizzleButton={true}
                  buttonClass="referring-provider-button contained"
                  compId={"sizzleTheaterButton"}
                  language={language}
                />
              </div>
            </div>
          }
        />
      )}
    </>
  )
}

export default ReferringProvider
