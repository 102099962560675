import React from "react"
import ImageMeta from "../../components/ImageMeta"
import NuvoImage from "../../components/NuvoImage"
import MarkdownViewer from "@components/MarkdownViewer"
import TheaterVideo from "@components/Theater/TheaterVideo"

const StaticHero = (props) => {
  const data = props.post.staticHero

  return (
    <>
      <div
        className="homepage-hero"
        style={{
          backgroundImage: `url(https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_99,w_500/v1/${data.backgroundImage})`,
        }}
      >
        <h1 id="SEOTitle">{props.post.seoHeading}</h1>

        <div className="hero-desktop">
          <div className="hero-side-image">
            <NuvoImage
              useAR
              cloudName="nuvolum"
              publicId={data.sideImage}
              responsive
            />
          </div>

          <div className="hero-side-content">
            <ImageMeta cloudName="nuvolum" publicId={data.logo} responsive />
            <MarkdownViewer markdown={data.heading} />

            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${props.post.sizzleYoutube}`}
              controls={true}
              playing={true}
              onlyButton={true}
              sizzleButton={true}
              buttonClass="sizzle-button-under contained"
              compId={"sizzleTheaterButton"}
              language={props.language}
            />
          </div>
        </div>
      </div>
      <div className="hero-text-mobile">
        <div>
          <MarkdownViewer markdown={data.heading} />

          <TheaterVideo
            videoUrl={`https://www.youtube.com/watch?v=${props.post.sizzleYoutube}`}
            controls={true}
            playing={true}
            onlyButton={true}
            sizzleButton={true}
            buttonClass="sizzle-button-under contained"
            compId={"sizzleTheaterButton"}
            language={props.language}
          />
        </div>
      </div>
    </>
  )
}

export default StaticHero
